<template>
  <div :class="['form-input-display', { 'form-input-display_open': isOpen }]">
    <div v-if="label" class="label">
      {{ label }}
    </div>

    <div
      :class="['form-input-display__selector', { 
        'form-input-display__selector_placeholder': isPlaceholder,
        'form-input-display__selector_desktop': !isMobile, 'form-input-display_has-error': hasError }]"
      @click="emit('click-selector')"
    >
      <div class="form-input-display__left">
        <atomic-image
          v-if="imgUrl"
          class="form-input-display__icon"
          :src="imgUrl"
          not-lazy
        />
        <div class="form-input-display__value">
          {{ value }}
        </div>
      </div>
      <div v-if="!isHideArrow" class="form-input-display__right">
        <atomic-icon id="arrow-down"/>
      </div>
    </div>
    <div v-if="!isMobile" class="form-input-display__list">
      <div class="form-input-display__list-header">
        <slot name="header" />
      </div>
      <div class="form-input-display__list-inner">
        <slot />
      </div>
      <div class="form-input-display__list-footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  withDefaults(defineProps<{
    label?: string;
    imgUrl?: string;
    value: string;
    isMobile?: boolean;
    isOpen: boolean;
    isHideArrow?: boolean;
    isPlaceholder?: boolean;
    hasError?: boolean;
  }>(), {
    isHideArrow: false,
    label: '',
    imgUrl: '',
  });

  const emit = defineEmits(['click-selector']);
</script>

<style lang="scss" src="~/assets/styles/components/form/input/display.scss" />
